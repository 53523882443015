import config from "../../data/SiteConfig";
export function getLanguage() {
  if (typeof window !== `undefined`) {
    const languages = ["tr"];
    const lang = window.location.pathname.substr(0, 4).replace(/\//gi, "");
    languages.indexOf(lang) > -1
      ? window.localStorage.setItem("localLocale", lang)
      : window.localStorage.setItem("localLocale", "en");
    return window.localStorage.getItem("localLocale");
  }
}

export function getUrlParameter(name) {
  if (typeof window !== `undefined`) {
    return (window.location.search.split(name + "=")[1] || "").split("&")[0];
  }
}

export function getData(locale) {
  switch (locale) {
    case "tr":
      return config.tr;
    default:
      return config.en;
  }
}

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}
