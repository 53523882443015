import React from "react";
import InstagramIcon from "../img/instagram.svg";
import { getData } from "../helper/helper";

const Instagram = ({ locale }) => {
  const { link, content } = getData(locale).header.instagram;

  if (!content) return null;

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      aria-label={content}
      className="instagram"
    >
      <InstagramIcon />
      <span
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </a>
  );
};

export default Instagram;
