const config = {
  siteTitle: "Dermatolog Dr. Ayşe Akkuş Kavak",
  siteTitleShort: "Dolgu, Botoks, Cild bakimi, Mezoterapi",
  siteDescription: "Dolgu, Botoks, Cild bakimi, Mezoterapi",
  siteLogo: "/img/logo.svg",
  copyright: "Copyright © 2023.",
  themeColor: "#1e73be",
  backgroundColor: "#e0e0e0",
  siteDomain: "https://drayseakkuskavak.com/",
  en: {
    language: "Language",
    blogPage: {
      title: "BLOG",
      description:
        "Dermatolog | Dr. Ayşe Akkuş Kavak",
      image: "/img/ameliyatsız-estetik.png",
      button: "SHOW MORE",
      read: "Keep Reading →",
      popular: "Popular Posts",
      related: "RELATED POSTS",
      info: [
        {
          title: "baslik",
          content: "icerik",
        },
      ],
    },
    agingPage: {
      title: "Medical Aesthetic",
      description:
        "In medical aesthetic treatments, we provide various methods to slowdown the aging process and retrieve your younger outlook.",
      image: "/img/antiaging.jpg",
      button: "SHOW MORE",
      read: "Keep Reading →",
      other: "OTHER SERVICES",
      related: "RELATED POSTS",
      info: [
        {
          title: "baslik",
          content: "icerik",
        },
      ],
    },
    laserPage: {
      title: "LASER TREATMENTS",
      description:
        "Laser Treatment is a method of removing unwanted hair or skin spots by practicing safe laser technologies.",
      image: "/img/laser-treatment.jpg",
      button: "SHOW MORE",
      read: "Keep Reading →",
      other: "OTHER SERVICES",
      related: "RELATED POSTS",
      info: [
        {
          title: "baslik",
          content: "icerik",
        },
      ],
    },
    bodyPage: {
      title: "Non-Surgical Body Contouring",
      description:
        "It is possible to attain a firmer skin without any surgical method, pain or ache.",
      image: "/img/body.jpg",
      button: "SHOW MORE",
      read: "Keep Reading →",
      other: "OTHER SERVICES",
      related: "RELATED POSTS",
      info: [
        {
          title: "Non-Surgical Body Contouring",
          content: "Non-Surgical Body Contouring",
        },
      ],
    },
    docPage: {
      title: "Documents",
      description: "Privacy Policy, Terms and Conditions, more.",
      image: "/img/about.jpg",
      button: "SHOW MORE",
      read: "Keep Reading →",
      other: "OTHER SERVICES",
      related: "RELATED POSTS",
    },
    skinPage: {
      title: "Skin Care",
      description:
        "It is possible to attain a firmer skin without any surgical method, pain or ache.",
      image: "/img/skincare.jpg",
      button: "SHOW MORE",
      read: "Keep Reading →",
      other: "OTHER SERVICES",
      related: "RELATED POSTS",
      info: [
        {
          title: "Skin Care",
          content: "Skin Care",
        },
      ],
    },
    modal: {
      title: "Legal warning",
      content:
        "<strong><em>Private XXX</em></strong>, the information on the website should not be considered as a medical basis by visitors, and should not be considered as a basis for any treatment or home practice. The information on this site is not for advertising and / or medical instructions, it is a preliminary information about laser epilation and various medical aesthetic applications. Please consult your physician.",
      button: "OK",
    },
    header: {
      title: "XXX",
      description:
        "Give us a call to get detailed information about our treatments <strong><a href='tel:+905323732364'> +90 532 373 2364</a></strong>",
      button: "BOOK FREE",
      home: "/",
      offerButton: "Make an Appointment",
      instagram: {
        link: "https://www.instagram.com/drayseakkus/",
        content: "Follow on Instagram",
      },
      whatsapp: {
        number: "905323732364",
        message: "Hello!",
        content: "Make an Appointment on Whatsapp",
      },
    },
    page404: {
      title: "PAGE NOT FOUND",
      content:
        "The page you are looking for is temporarily unavailable. If you keep coming back here please.",
      button: "contact us",
      url: "/contact/",
    },
    footer: {
      title: "Dermatolog<strong>Dr. Ayşe Akkuş Kavak</strong>",
      description: "Kozmetik dermatoloji alanında botoks uygulamaları, dolgu maddeleri, lazer, yüz ve cilt gençleştirme uygulamaları, lipoliz ve mezoterapi ile ilgilenmektedir.",
      address:
        "Fevzi Çakmak Mah, D100, Eski Karakol Sk No:9, 34899 Pendik/İstanbul<br/> <a href='tel:+905323732364'> +90 532 373 2364</a>",
      socialTitle: "FOLLOW US",
      contactUs: "CONTACT US",
      virtual: "Virtual Tour",
    },
    bookForm: {
      title: "REQUEST A BOOK",
      name: "Name",
      email: "E-mail Address",
      phone: "Phone Number",
      countryCode: "Country Code",
      messages: "Messages",
      button: "SUBMIT",
    },
    indexPage: {
      faqButton: "SHOW MORE",
      faqUrl: "/faq/",
    },
    contactPage: {
      title: "Thanks for your interest",
      content: "We shall contact you soon.",
    },
  },
  tr: {
    language: "Dil",
    blogPage: {
      title: "BLOG",
      description:
        "Dermatolog | Dr. Ayşe Akkuş Kavak",
      image: "/img/ameliyatsız-estetik.png",
      button: "DAHA FAZLASI",
      read: "Okumaya devam et →",
      popular: "Popüler Yazılar",
      related: "BAĞLANTILI YAZILAR",
      info: [
        {
          title: "baslik",
          content: "icerik",
        },
      ],
    },
    laserPage: {
      title: "LAZER TEDAVİLERİ",
      description:
        "Lazer teknolojisi kullanılarak, vücutta bulunan istenmeyen tüyleri veya cildinizde bulunan lekeleri güvenli bir şekilde yok etme yöntemidir.",
      image: "/img/laser-treatment.jpg",
      button: "DAHA FAZLASI",
      read: "Okumaya devam et →",
      other: "Diğer Hizmetlerimiz",
      related: "BAĞLANTILI YAZILAR",
      info: [
        {
          title: "LAZER TEDAVİLERİ",
          content: "LAZER TEDAVİLERİ",
        },
      ],
    },
    docPage: {
      title: "Dökümanlar",
      description: "Kullanım Koşulları, Çerez Politikası vb.",
      image: "/img/about.jpg",
      button: "DAHA FAZLASI",
      read: "Okumaya devam et →",
      other: "Diğer Hizmetlerimiz",
      related: "BAĞLANTILI YAZILAR",
    },
    bodyPage: {
      title: "AMELİYATSIZ VÜCUT ŞEKİLLENDİRME",
      description:
        "Herhangi bir ameliyat işlemine gerek kalmadan ağrısız ve acısız bir şekilde, çeşitli yöntemler kullanılarak cildinizin daha sıkı bir görünüme kavuşması artık mümkün.",
      image: "/img/body.jpg",
      button: "DAHA FAZLASI",
      read: "Okumaya devam et →",
      other: "Diğer Hizmetlerimiz",
      related: "BAĞLANTILI YAZILAR",
      info: [
        {
          title: "MELİYATSIZ VÜCUT ŞEKİLLENDİRME",
          content: "MELİYATSIZ VÜCUT ŞEKİLLENDİRME",
        },
      ],
    },
    skinPage: {
      title: "Cilt Bakımı",
      description:
        "XXX ile herhangi bir cerrahi yöntem, ağrı veya ağrı olmadan daha sıkı bir cilde ulaşmak mümkündür.",
      image: "/img/skincare.jpg",
      button: "SHOW MORE",
      read: "Keep Reading →",
      other: "Diğer Hizmetlerimiz",
      related: "BAĞLANTILI YAZILAR",
      info: [
        {
          title: "Cilt Bakımı",
          content: "Cilt Bakımı",
        },
      ],
    },
    modal: {
      title: "Yasal Uyarı",
      content:
        "<strong><em>XXX</em></strong>, web sitesinde yer alan bilgiler ziyaretçiler tarafından tıbbi dayanak olarak ele alınmamalı, hiç bir tedavi veya evde uygulama için dayanak kabul edilmemelidir. Bu sitede yer alan bilgiler, reklam ve/veya tıbbi yönerge amaçlı değildir, lazer epilasyon ve çeşitli medikal estetik uygulamaları hakkında ön bilgilendirme niteliğindedir. Lütfen hekiminize danışın.",
      button: "Tamam",
    },
    header: {
      title: "XXX",
      description:
        "Uygulamalarımız hakkında detaylı bilgi almak için hemen bizi arayın. <strong><a href='tel:+905323732364'> 0532 373 2364</a></strong>",
      button: "REZERVASYON",
      home: "/tr/",
      instagram: {
        link: "https://www.instagram.com/drayseakkus/",
        content: "Instagram'da Takip Edin",
      },
      whatsapp: {
        number: "905323732364",
        message: "Merhaba",
        content: "Whatsapp'dan Randevu Alın",
      },
      offerButton: "Randevu Alın",
    },
    footer: {
      title: "Dermatolog<strong>Dr. Ayşe Akkuş Kavak</strong>",
      description: "Dermatoloji Uzmanı Dr. Ayşe Akkuş Kavak, Lazer Epilasyon, Mezoterapi, Karbon Peeling, Bölgesel Zayıflama, Dolgu Tedavileri hizmetleri vermektedir.",
      address:
        "Fevzi Çakmak Mah, D100, Eski Karakol Sk No:9, 34899 Pendik/İstanbul<br/> <a href='tel:+905323732364'> +90 532 373 2364</a>",
      socialTitle: "Bizi Takip Edin",
      contactUs: "Bize Ulaşın",
    },
    page404: {
      title: "Sayfa Bulunamadı",
      content:
        "Aradığınız sayfa geçici olarak kullanılamıyor. Buraya geri dönmeye devam ederseniz lütfen bizimle iletisime geciniz.",
      button: "Bize Ulaşın",
      url: "/tr/iletisim/",
    },
    bookForm: {
      title: "RANDEVU AL",
      name: "Adınız",
      email: "E-mail Adresiniz",
      phone: "Telefon Numaranız",
      countryCode: "Ülke Kodu",
      messages: "Mesajınız",
      button: "GÖNDER",
    },
    indexPage: {
      faqButton: "DAHA FAZLASI",
      faqUrl: "/tr/faq/",
    },
    contactPage: {
      title: "İlginiz için teşekkür ederiz",
      content: "En kısa sürede sizinle iletişime geçilecektir.",
    },
  },
  siteMenu: [
    {
      en: "Home Page",
      tr: "Ana Sayfa",
      url: "/",
    },
    {
      en: "About Me",
      tr: "Hakkımda",
      url: "/hakkimda/",
    },
    {
      en: "Non-Surgical Aesthetics",
      tr: "Ameliyatsız Estetik",
      url: "/ameliyatsiz-estetik/",
    },
    {
      en: "Laser Treatments",
      tr: "Lazer Tedavileri",
      url: "/lazer-tedavileri/",
    },
    {
      en: "Skin Diseases",
      tr: "Cilt Hastalıkları",
      url: "/cilt-hastaliklari/",
    },
    {
      en: "Contact Us",
      tr: "İletişim",
      url: "/iletisim/",
    },
    {
      en: "Blog",
      tr: "Blog",
      url: "/blog/",
    },
  ],
  topMenu: [
    {
      en: "Blog",
      tr: "Blog",
      url: "/blog/",
    },
    {
      en: "Contact Us",
      tr: "Bize Ulaşın",
      url: "/iletisim/",
    },
  ],
  socialMenu: [
    {
      id: "instagram",
      icon: "/img/social/social-instagram.svg",
      url: "https://www.instagram.com/drayseakkus/",
    },
    {
      id: "whatsapp",
      icon: "/img/social/social-whatsapp.svg",
      url: "https://wa.me/905323732364?text=Merhaba",
    },
  ],
};

module.exports = config;
