import React from "react";
import Logo from "../img/whatsapp.svg";
import { getData } from "../helper/helper";

const WhatsApp = ({ locale }) => {
  const { message, number, content } = getData(locale).header.whatsapp;

  if(!message) return null

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://wa.me/${number}?text=${message}`}
      aria-label={content}
      className="whatsapp"
    >
      <Logo />
      {content && <span>{content}</span>}
    </a>
  );
};

export default WhatsApp;
