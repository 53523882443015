import { getLanguage } from "./helper";
export default function validateInfo(values) {
  const locale = getLanguage();

  let errors = {};

  const error =
    locale === "en"
      ? "This field is required"
      : locale === "de"
      ? "Dieses Feld ist erforderlich"
      : "Bu alan gereklidir";
  const errorName =
    locale === "en"
      ? "Enter a valid name"
      : locale === "de"
      ? "Geben Sie einen gültigen Namen ein"
      : "Lutfen gecerli bir isim giriniz";
  const errorEmail =
    locale === "en"
      ? "Email is not valid"
      : locale === "de"
      ? "Email ist ungültig"
      : "Lutfen geçerli bir e-posta adresi giriniz";
  const errorPhone =
    locale === "en"
      ? "Phone number needs to be 7 characters or more"
      : locale === "de"
      ? "Die Telefonnummer muss mindestens 7 Zeichen lang sein"
      : "Telefon numaraniz en az 7 karakterli olmalidir";
  const errorMessage =
    locale === "en"
      ? "Please given more details"
      : locale === "de"
      ? "Bitte geben Sie weitere Details an"
      : "Lutfen daha fazla detay giriniz";

  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  if (!values.name) {
    errors.name = error;
  } else if (!/^[A-Za-z]+/.test(values.name.trim())) {
    errors.name = errorName;
  }

  if (!values.email) {
    errors.email = error;
  } else if (!pattern.test(values.email)) {
    errors.email = errorEmail;
  }
  if (!values.phone) {
    errors.phone = error;
  } else if (values.phone.length < 9) {
    errors.phone = errorPhone;
  }

  if (!values.message) {
    errors.message = error;
  } else if (values.message.length < 6) {
    errors.message = errorMessage;
  }
  return errors;
}
