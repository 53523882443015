import React from "react";
import { Link } from "gatsby";
import Navbar from "./Navbar";
import ApplicationForm from "./ApplicationForm";
import Logo from "../img/logo.svg";
import { getData } from "../helper/helper";
import Close from "../img/close.svg";
import Top from "../img/top.svg";
import Instagram from "./Instagram";
import WhatsApp from "./WhatsApp";

/* eslint-disable */
const Header = class extends React.Component {
  constructor() {
    super();
    this.state = {
      active: false,
      scroll: false,
      title: "",
    };
  }

  componentDidMount() {
    window.onscroll = () => {
      if (
        document.body.scrollTop > 240 ||
        document.documentElement.scrollTop > 240
      ) {
        this.setState({ scroll: true });
      } else {
        this.setState({ scroll: false });
      }
    };
  }
  toggleBook = (title) => {
    this.setState({
      active: !this.state.active,
      title,
    });
  };
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  render() {
    const locale = this.props.locale;
    const header = getData(locale).header;
    const { active, scroll } = this.state;

    return (
      <>
        <a
          className="popOffer"
          aria-hidden="false"
          target="_blank"
          rel="noopener noreferrer"
          href="https://wa.me/905323732364"
        >
          {header.offerButton}
        </a>
        {scroll && (
          <>
            {/* <div className="infobar active">
              <div className="container">
                <WhatsApp locale={locale} />
              </div>
            </div> */}
            <div onClick={() => this.scrollToTop()} className="backtotop">
              <Top />
            </div>
          </>
        )}
        <div className="header" id="top">
          <div className="container">
            <div className="logo">
              <div className="left">
                <Link
                  to={header.home}
                  alt={header.title}
                  aria-label="Dermatolog Dr. Ayşe Akkuş Kavak"
                  title="Dermatolog Dr. Ayşe Akkuş Kavak"
                >
                  <Logo />
                  <span>
                    Dermatolog<strong>Dr. Ayşe Akkuş Kavak</strong>
                  </span>
                </Link>
              </div>
              <div className="right">
                <Instagram locale={locale} />
                <WhatsApp locale={locale} />
              </div>
            </div>
          </div>
          <div className="navbar">
            <div className="container">
              <Navbar locale={locale} />
            </div>
          </div>
        </div>
        {active && (
          <div className="bookModal">
            <ApplicationForm title={this.state.title} />
            <div className="close" onClick={() => this.toggleBook(null)}>
              <Close />
            </div>
            <div
              className="overlay"
              onClick={() => this.toggleBook(null)}
            ></div>
          </div>
        )}
      </>
    );
  }
};

export default Header;
