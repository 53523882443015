import React from "react";
import { Link } from "gatsby";
import config from "../../data/SiteConfig";
import { getData } from "../helper/helper";
import Logo from "../img/logo.svg";

const Footer = class extends React.Component {
  render() {
    const locale = this.props.locale;
    const socialMenu = config.socialMenu;
    const footer = getData(locale).footer;
    // const header = getData(locale).header;
    return (
      <footer className="footer">
        <div className="container">
          <div className="columns">
            <div className="column">
              <Link to="/">
                <Logo className="logo" />
              </Link>
            </div>
            <div className="column">
              <h3 dangerouslySetInnerHTML={{ __html: footer.title }} />
              <p>{footer.description}</p>
            </div>
            <div className="column">
              <div className="title">{footer.contactUs}</div>
              {/* {locale !== "de" && (
                <p dangerouslySetInnerHTML={{ __html: header.description }} />
              )} */}
              <p dangerouslySetInnerHTML={{ __html: footer.address }} />
            </div>
            <div className="column social">
              <div className="title">{footer.socialTitle}</div>
              {socialMenu &&
                socialMenu.map((data) => {
                  return (
                    <a
                      rel="noopener noreferrer"
                      key={data.id}
                      title={data.id}
                      href={data.url}
                      target="_blank"
                    >
                      <img
                        src={data.icon}
                        alt={data.id}
                        width="40"
                        height="40"
                      />
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
