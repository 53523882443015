import React from "react";
import { Link } from "gatsby";
import config from "../../data/SiteConfig";
import Language from "./Language";
import { getData } from "../helper/helper";

const Navbar = class extends React.Component {
  constructor() {
    super();
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }
  componentDidUpdate() {
    this.state.active
      ? document.querySelector(".header").classList.add("active")
      : document.querySelector(".header").classList.remove("active");
  }

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  renderNav(locale) {
    const Menu = config.siteMenu;
    switch (locale) {
      case "de":
        return (
          Menu &&
          Menu.map((data) => {
            return (
              <li key={data.de}>
                <Link
                  onClick={() => this.toggleHamburger()}
                  className="navbar-item"
                  to={`/de${data.url}`}
                  activeClassName="active"
                  title={data.de}
                >
                  {data.de}
                </Link>
              </li>
            );
          })
        );
      case "tr":
        return (
          Menu &&
          Menu.map((data) => {
            return (
              <li key={data.tr}>
                <Link
                  onClick={() => this.toggleHamburger()}
                  className="navbar-item"
                  to={`/tr${data.url}`}
                  activeClassName="active"
                  title={data.tr}
                >
                  {data.tr}
                </Link>
              </li>
            );
          })
        );
      default:
        return (
          Menu &&
          Menu.map((data) => {
            return (
              <li key={data.en}>
                <Link
                  onClick={() => this.toggleHamburger()}
                  className="navbar-item"
                  to={data.url}
                  activeClassName="active"
                  title={data.en}
                >
                  {data.en}
                </Link>
              </li>
            );
          })
        );
    }
  }

  render() {
    const locale = this.props.locale;
    const language = getData(locale).language;
    return (
      <nav>
        <div className="mobile">
          <div
            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
            data-target="navMenu"
          >
            <div
              className="menu"
              onClick={() => this.toggleHamburger()}
              aria-hidden="true"
            >
              <span />
              <span />
              <span />
            </div>
            {this.state.active && (
              <ul>
                {this.renderNav(locale)}
                <div className="language">
                  <li>
                    {language + ''}
                    <Language
                      locale={locale}
                      toggleHamburger={this.toggleHamburger}
                    />
                  </li>
                </div>
              </ul>
            )}
          </div>
        </div>

        <ul className="desktop">{this.renderNav(locale)}</ul>
      </nav>
    );
  }
};

export default Navbar;
