import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import useForm from "../hooks/useForm";
import validate from "../helper/validateInfo";
import PhoneInput from "react-phone-input-2";
import tr from "react-phone-input-2/lang/tr.json";
import de from "react-phone-input-2/lang/de.json";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { navigate } from "gatsby-link";
import { getLanguage, getData } from "../helper/helper";
import "react-phone-input-2/lib/material.css";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& label.Mui-focused": {
      color: "#3c3c3c",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#c0855c",
    },
  },
  textField: {
    margin: "10px 0 0 0 !important",
    width: "100%",
    backgroundColor: "#fff",
  },
  button: {
    margin: "20px 0",
    backgroundColor: "#c0855c",
    minHeight: "44px",
    boxShadow: "none",
    border: "solid 1px #c0855c",
    borderRadius: "0",
    "& .MuiButton-label": {
      fontWeight: "600",
      letterSpacing: "3px",
    },
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "none",
    },
  },
}));

const ApplicationForm = ({ title, from }) => {
  const classes = useStyles();
  const locale = getLanguage();
  const book = getData(locale).bookForm;
  const form = useRef();
  const [style, setStyle] = useState("");
  const action =
    locale === "en" ? "/contact/thanks/" : `/${locale}/contact/thanks/`;
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
  );

  const fullPath = typeof window !== "undefined" && window.location.href;

  const accessToken = () => {
    switch (locale) {
      case "tr":
        return "b098720d-78c5-4050-812a-c0e981f69be9";
      case "de":
        return "78c1173e-4fea-436e-a823-c1a53104e453";

      default:
        return "70ab631b-cb1c-45c5-a567-437be93d6dbc";
    }
  };

  function submitForm() {
    setStyle("loader");

    const formdata = new FormData();
    formdata.append("leadName", values.name);
    formdata.append("message", values.message);
    formdata.append("leadMail", values.email);
    formdata.append("leadPhone", values.phone);
    formdata.append("subject", title ? title : book.title);
    formdata.append("reference", fullPath);
    formdata.append("tid", "127");
    formdata.append("accessToken", accessToken());

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "*/*",
        BranchID: process.env.BRANCH_ID,
        Authorization: process.env.API_KEY,
      },
      body: formdata,
      redirect: "follow",
    };

    fetch(process.env.GATSBY_ENDPOINT, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        navigate(form.current.getAttribute("action"));
      })
      .catch((error) => console.log("error", error));

    emailjs
      .sendForm(
        "service_fq53z8p",
        "zen_contact_form",
        form.current,
        "user_z2pzeOzhloym5wVKqR123"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  const lang = () => {
    switch (locale) {
      case "tr":
        return tr;
      case "de":
        return de;

      default:
        return null;
    }
  };

  const pathArray =
    typeof window !== "undefined" && window.location.pathname.split("/");
  return (
    <form
      className={classes.container + ` contactForm`}
      method="POST"
      action={action}
      onSubmit={handleSubmit}
      ref={form}
      noValidate
    >
      <div className="form-title">{title ? title : book.title}</div>
      <p className="hidden">
        <input
          type="hidden"
          name="from_name"
          value={
            from
              ? from
              : title
              ? title + ` - ` + pathArray[pathArray.length - 2]
              : book.title + ` - ` + pathArray[pathArray.length - 2]
          }
        />
        <input type="hidden" name="url" value={fullPath} />
      </p>

      <TextField
        className={classes.textField}
        error={errors.name ? true : false}
        id="name"
        label={book.name}
        name="name"
        defaultValue={values.name}
        onChange={handleChange}
        variant="outlined"
      />
      {errors.name && (
        <p style={{ color: "red", marginLeft: "15px", fontSize: "0.75rem" }}>
          {errors.name}
        </p>
      )}
      <div className="phone-fields">
        <PhoneInput
          className={classes.textField}
          localization={lang}
          specialLabel={book.phone}
          country={locale === "tr" ? "tr" : locale === "de" ? "de" : "gb"}
          value={values.phone}
          placeholder={book.phone}
          name="phone"
          enableSearch
          onChange={handleChange}
          inputProps={{
            required: true,
            name: "phone",
          }}
        />
        {errors.phone && (
          <p style={{ color: "red", marginLeft: "15px", fontSize: "0.75rem" }}>
            {errors.phone}
          </p>
        )}
      </div>
      <TextField
        className={classes.textField}
        error={errors.email ? true : false}
        id="email"
        label={book.email}
        name="email"
        defaultValue={values.email}
        onChange={handleChange}
        variant="outlined"
      />
      {errors.email && (
        <p style={{ color: "red", marginLeft: "15px", fontSize: "0.75rem" }}>
          {errors.email}
        </p>
      )}
      <TextField
        className={classes.textField}
        error={errors.message ? true : false}
        style={{ margin: "10px 0" }}
        id="message"
        label={book.messages}
        name="message"
        defaultValue={values.message}
        onChange={handleChange}
        variant="outlined"
        multiline
      />
      {errors.message && (
        <p style={{ color: "red", marginLeft: "15px", fontSize: "0.75rem" }}>
          {errors.message}
        </p>
      )}
      <Button variant="contained" type="submit" className={classes.button}>
        <span className={style}></span>
        {book.button}
      </Button>
    </form>
  );
};

export default ApplicationForm;
