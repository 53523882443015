import React from "react";
import { navigate } from "gatsby";

const Language = class extends React.Component {
  onChange = (e) => {
    const pathArray = window.location.pathname.split("/");
    const isTurkish = pathArray[1] === "tr";
    const isGerman = pathArray[1] === "de";
    const lastItem = isTurkish || isGerman ? pathArray[2] : pathArray[1];

    if (e.target.value === "en") {
      navigate(`/${lastItem}`, { replace: true });
    } else {
      navigate(`/${e.target.value}/${lastItem}`, { replace: true });
    }

    if (typeof window !== `undefined`) {
      window.localStorage.setItem("localLocale", e.target.value);
    }
  };
  render() {
    const toggleHamburger = this.props;
    const locale = this.props.locale;
    return (
      <div className="dropBox">
        <select
          onBlur={this.onChange}
          onChange={this.onChange}
          value={locale}
          aria-hidden="true"
        >
          <option value="tr" onClick={() => toggleHamburger}>
            Türkçe
          </option>
          <option value="en" onClick={() => toggleHamburger}>
            English
          </option>
        </select>
      </div>
    );
  }
};

export default Language;
